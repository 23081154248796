var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: _vm.ScrollY + '%',
    background:
      'linear-gradient(' +
      _vm.sColor[0] +
      ',' +
      _vm.sColor[1] +
      ',' +
      _vm.sColor[2] +
      ')',
    height: _vm.sHeight + 'em',
  })})}
var staticRenderFns = []

export { render, staticRenderFns }