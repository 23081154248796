<template>
  <div
    :style="{
      width: ScrollY + '%',
      background:
        'linear-gradient(' +
        sColor[0] +
        ',' +
        sColor[1] +
        ',' +
        sColor[2] +
        ')',
      height: sHeight + 'em',
    }"
  />
</template>

<script>
export default {
  props: {
    sHeight: {
      type: Number,
      default: 0.25,
    },
    sColor: {
      type: Array,
      default: function () {
        return ["to right", "#ffc107", "#ffc107"];
      },
    },
  },
  data() {
    return {
      scrollY: 0,
    };
  },
  watch: {
    currentRoute() {
      this.scrollY = 0;
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    ScrollY() {
      return this.$route.path === "/" ? 0 : this.scrollY;
    },
  },
  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
    window.dispatchEvent(new Event("scroll"));
  },
  methods: {
    handleScroll() {
      this.scrollY = parseInt(
        (window.scrollY /
          (document.documentElement.scrollHeight -
            document.documentElement.clientHeight)) *
          100
      );
    },
  },
};
</script>

<style scoped>
div {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 9999;
}
</style>
